// extracted by mini-css-extract-plugin
export var artworkImg = "przedstawienie-module--artwork-img--Ih9CJ";
export var bodyCol = "przedstawienie-module--body-col--UBPuj";
export var copyright = "przedstawienie-module--copyright--U44Og";
export var credits = "przedstawienie-module--credits--aDTOd";
export var hl = "przedstawienie-module--hl--C-YRv";
export var lnkContainer = "przedstawienie-module--lnk-container--daFZC";
export var lnkWrapper = "przedstawienie-module--lnk-wrapper--cOyH+";
export var logo = "przedstawienie-module--logo--Gy2Pc";
export var streamIco = "przedstawienie-module--stream-ico--epXK9";
export var streamList = "przedstawienie-module--stream-list--g7hkB";
export var titl = "przedstawienie-module--titl--enpZm";