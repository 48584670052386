import * as React from "react"
import * as styles from './przedstawienie.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import {SiSpotify, SiApplemusic, SiYoutubemusic, SiTidal, SiDeezer} from 'react-icons/si';
import Logo from "../../images/assets/logo_outline.svg"
import { Link } from 'gatsby'
import SEO from "../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"




const streamPrzestawienie = () => {

    const streamLinks = {
        spotify: "https://open.spotify.com/album/7EdwcO8FeXHe8Bs0yqMxAH",
        appleMusic: "https://music.apple.com/pl/album/przedstawienie-ep/1539309370",
        youtubeMusic: "https://music.youtube.com/playlist?list=OLAK5uy_k3CCQx0aFeynJCENAb6NuCI2phRsaB8FE",
        tidal: "https://listen.tidal.com/album/161379742",
        deezer: "https://www.deezer.com/pl/album/184862492"
    }

    return (
        <div className={styles.lnkContainer}>
            <SEO title="Przedstawienie" bodyClass={styles.bodyCol}/>
            <div className={styles.lnkWrapper}>
                <StaticImage
                    alt="The artwork of Przestawienie, the EP"
                    src="../../images/przedstawienie_artwork.jpg"
                    className={styles.artworkImg}
                /> 

                <h1 className={styles.titl}>POSŁUCHAJ TUTAJ</h1>

                <div className={styles.streamList}>
                    <OutboundLink href={streamLinks.spotify} tabIndex="0" className={styles.streamIco} aria-label="Stream Spotify"><SiSpotify aria-hidden="true" size="2rem"/>
                        <span>SPOTIFY</span></OutboundLink>
                    <OutboundLink href={streamLinks.appleMusic} tabIndex="0" className={styles.streamIco} aria-label="Stream Apple Music"><SiApplemusic aria-hidden="true" size="2rem"/>
                        <span>APPLE MUSIC</span></OutboundLink>
                    <OutboundLink href={streamLinks.youtubeMusic} tabIndex="0" className={styles.streamIco} aria-label="Stream Youtube Music"><SiYoutubemusic aria-hidden="true" size="2rem"/>
                        <span>YOUTUBE MUSIC</span></OutboundLink>
                    <OutboundLink href={streamLinks.tidal} tabIndex="0" className={styles.streamIco} aria-label="Stream Tidal"><SiTidal aria-hidden="true" size="2rem"/>
                        <span>TIDAL</span></OutboundLink>
                    <OutboundLink href={streamLinks.deezer} tabIndex="0" className={styles.streamIco} aria-label="Stream Deezer"><SiDeezer aria-hidden="true" size="2rem"/>
                        <span>DEEZER</span></OutboundLink>
                </div>

                <div className={styles.hl}></div>

                <div className={styles.credits}>
                    <div className={styles.logo} ><Link to="/" aria-label="Ambaras Strona Głowna"><Logo aria-hidden="true" /></Link></div>
                    <p className={styles.copyright}>℗ 2021 AMBARASSED RECORDS</p>
                </div>
            </div>
        </div>
    )
}

export default streamPrzestawienie
